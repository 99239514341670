<ng-container *transloco="let t; prefix: translationScope">
    @if (!hasDataLoadingError) {
        <form [formGroup]="treatmentInfoForm">
            <div class="mb-6">
                <big-ui-label
                    #treatmentCostLabel
                    class="mb-2"
                    for="treatmentCost"
                    [label]="t('label.treatmentCost')"
                    [isRequired]="true" />
                <big-ui-currency-input
                    [id]="'treatmentCost'"
                    [isValid]="treatmentInfoForm.controls.treatmentCost.valid"
                    [isTouched]="treatmentInfoForm.controls.treatmentCost.touched"
                    [isRequired]="true"
                    [showValidation]="hasBeenSubmitted"
                    formControlName="treatmentCost"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: treatmentCostLabel.label!,
                        valid: !hasBeenSubmitted || treatmentInfoForm.controls.treatmentCost.valid,
                    }" />
                @if (hasBeenSubmitted) {
                    <big-ui-error
                        for="treatmentCost"
                        class="mt-2"
                        [errors]="treatmentInfoForm.controls.treatmentCost.errors" />
                }
            </div>
            <div class="mb-6">
                <big-ui-label
                    #regularTreatmentLabel
                    class="mb-2"
                    for="regularTreatment"
                    [label]="t('label.regularTreatment')"
                    [isRequired]="true" />
                <big-ui-select
                    [id]="'regularTreatment'"
                    [isValid]="treatmentInfoForm.controls.regularTreatment.valid"
                    [options]="regularTreatmentOptions"
                    [isRequired]="true"
                    [showValidation]="hasBeenSubmitted"
                    formControlName="regularTreatment"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: regularTreatmentLabel.label!,
                        valid: !hasBeenSubmitted || treatmentInfoForm.controls.regularTreatment.valid,
                    }" />
                @if (hasBeenSubmitted) {
                    <big-ui-error
                        for="regularTreatment"
                        class="mt-2"
                        [errors]="treatmentInfoForm.controls.regularTreatment.errors"
                        [overrides]="selectErrorOverrides" />
                }
            </div>
        </form>
        @if (tableRows.length > 0) {
            <big-ui-table
                class="mt-8 mb-4"
                [rows]="tableRows"
                [headers]="tableHeaders"
                [footerRows]="tableFooterRows" />
        }
        @if (tableRows.length > 0) {
            <span class="mb-8 text-sm italic">{{ t('label.noGuarantee') }}</span>
        }
        <form
            [formGroup]="evidenceAndTeethForm"
            (ngSubmit)="submit($event)">
            <h4 class="my-8">{{ t('label.evidenceAndTeethHeading') }}</h4>
            <div class="mb-6">
                <big-ui-label
                    #evidenceNumberLabel
                    class="mb-2"
                    for="evidenceNumber"
                    [label]="t('label.evidenceNumber')"
                    [isRequired]="true" />
                <big-ui-select
                    [id]="'evidenceNumber'"
                    [isValid]="evidenceAndTeethForm.controls.evidenceNumber.valid"
                    [disableTransloco]="true"
                    [options]="evidenceNummberOptions"
                    [isRequired]="true"
                    [showValidation]="hasBeenSubmitted && !hasBeenReset"
                    formControlName="evidenceNumber"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: evidenceNumberLabel.label!,
                        valid: !hasBeenSubmitted || hasBeenReset || evidenceAndTeethForm.controls.evidenceNumber.valid,
                    }" />
                @if (hasBeenSubmitted && !hasBeenReset) {
                    <big-ui-error
                        for="evidenceNumber"
                        class="mt-2"
                        [errors]="evidenceAndTeethForm.controls.evidenceNumber.errors"
                        [overrides]="selectErrorOverrides" />
                }
            </div>
            <div class="mb-6">
                <big-ui-label
                    #numberOfTeethLabel
                    class="mb-2"
                    for="numberOfTeeth"
                    [label]="t('label.numberOfTeeth')"
                    [isRequired]="true" />
                <big-ui-select
                    [id]="'numberOfTeeth'"
                    [isValid]="evidenceAndTeethForm.controls.numberOfTeeth.valid"
                    [options]="numberOfTeethOptions"
                    [isRequired]="true"
                    [showValidation]="hasBeenSubmitted && !hasBeenReset"
                    formControlName="numberOfTeeth"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: numberOfTeethLabel.label!,
                        valid: !hasBeenSubmitted || hasBeenReset || evidenceAndTeethForm.controls.numberOfTeeth.valid,
                    }" />
                @if (hasBeenSubmitted && !hasBeenReset) {
                    <big-ui-error
                        for="numberOfTeeth"
                        class="mt-2"
                        [errors]="evidenceAndTeethForm.controls.numberOfTeeth.errors"
                        [overrides]="selectErrorOverrides" />
                }
            </div>
            <big-ui-button
                [buttonStyle]="'secondary'"
                class="block mt-10"
                (clicked)="submit($event)">
                {{ t('label.submit') }}
            </big-ui-button>
        </form>
    } @else {
        <big-ui-alert
            [title]="t('error.title')"
            [classList]="'flex text-center border-2 rounded-md flex-col p-4!'"
            type="danger">
            {{ t('error.unavailable', { number: errorPhoneNumber.title }) }}
        </big-ui-alert>
    }

    <ng-template #error>
        <big-ui-alert
            [title]="t('error.title')"
            [classList]="'flex text-center border-2 rounded-md flex-col p-4!'"
            type="danger">
            {{ t('error.unavailable', { number: errorPhoneNumber.title }) }}
        </big-ui-alert>
    </ng-template>
</ng-container>
